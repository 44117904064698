import { jsPDF } from "jspdf";
import "jspdf-autotable";
import {
  dateToDateString,
  ellipsisString,
  formatDateNormal,
} from "@/app/infrastructures/misc/Utils";
import { StiDestDetail, StiDestManifestData } from "@/domain/entities/StiDest";

let doc: any;

const drawCellDataConditions = (cell: any) => [
  cell.column.dataKey === "totalPieces" &&
    (cell.row.section === "head" || cell.row.section === "body"),
  cell.column.dataKey === "productType" && cell.row.section === "head",
  cell.column.dataKey === "productType" && cell.row.section === "body",
  cell.column.dataKey === "origin" && cell.row.section === "body",
];

const drawCellData = (cell: any) => {
  if (cell.row.index < 0) {
    return;
  }

  if (drawCellDataConditions(cell)[2]) {
    doc.setFillColor("#ebecf0");
    cell.row.cells["productType"].text
      .filter((item: any) => item !== "")
      .map((_: any, index: number) => {
        doc.roundedRect(
          cell.row.cells["productType"].x + 0.7, //x
          cell.row.cells["productType"].y + //y
            cell.row.cells["productType"].contentHeight /
              (1.6333 +
                1.75 *
                  cell.row.cells["totalPieces"].text.filter(
                    (item: any) => item !== ""
                  ).length) +
            (0.0083 + 5.675 * index),
          cell.row.cells["productType"].contentWidth - 1,
          3,
          1,
          1,
          "F"
        );
      });
    doc.setFillColor("#fff");
  }
  if (drawCellDataConditions(cell)[3]) {
    doc.setFillColor("#ebecf0");
    cell.row.cells["origin"].text
      .filter((item: any) => item !== "")
      .map((_: any, index: number) => {
        doc.roundedRect(
          cell.row.cells["origin"].x + 0.7, //x
          cell.row.cells["origin"].y //y 
          + 2.55,
          cell.row.cells["origin"].contentWidth - 1,
          3,
          1,
          1,
          "F"
        );
      });
    doc.setFillColor("#fff");
  }
};

const generateManifest = (
  head: StiDestDetail,
  data: any,
  logo: string
) => {
  doc = new jsPDF("p", "mm", "a4", true);
  const pdfWidth = doc.internal.pageSize.width;
  const logoProperties = doc.getImageProperties(logo);
  const logoAspectRatio = logoProperties.height / logoProperties.width;

  const imageWidth = 40;
  const imageHeight = logoAspectRatio * 40;
  doc.addImage(
    logo,
    "PNG",
    pdfWidth - imageWidth - 9,
    11,
    imageWidth,
    imageHeight,
    "logo",
    "FAST",
    0
  );
  doc.setFont("helvetica", "bold");
  doc.setFontSize(16);
  doc.setTextColor("#4d4d4d");
  doc.text(`STI-DEST Manifest - ${head.stiDestId}`, 10, 18);
  doc.setFillColor("#e0e0e0");
  doc.rect(10, 25, 190, 0.2, "F");
  doc.setFont("helvetica", "normal");
  doc.setFontSize(7);
  doc.setTextColor("#4d4d4d");
  // left column
  doc.text("Nama Consolidator", 10, 31);
  doc.text(":", 43, 31);
  doc.text(ellipsisString(head.consolidatorName, 70), 45, 31);

  doc.setTextColor("#4d4d4d");
  doc.text("Kota Consolidator", 10, 37);
  doc.text(":", 43, 37);
  doc.text(`${head.originCityCode} - ${head.originConsolidator}`, 45, 37);

  // center column
  doc.setFont("helvetica", "normal");
  doc.setFontSize(7);
  doc.setTextColor("#4d4d4d");
  doc.text("Total STT/Koli", 85, 31);
  doc.text(":", 110, 31);
  doc.text(`${String(head.totalStt)}/${String(head.totalPiece)}`, 112, 31);

  doc.setFont("helvetica", "normal");
  doc.setFontSize(7);
  doc.setTextColor("#4d4d4d");
  doc.text("Berat Kotor", 85, 37);
  doc.text(":", 110, 37);
  doc.text(`${String(head.totalGrossWeight)} kg`, 112, 37);

  // right column
  doc.setFont("helvetica", "normal");
  doc.setFontSize(7);
  doc.setTextColor("#4d4d4d");
  doc.text("Tanggal Manifest", 140, 31);
  doc.text(":", 168, 31);
  doc.text(formatDateNormal(head.stiDestDate, "DD MMMM YYYY HH:mm:ss"), 170, 31);

  let lastPage = 0;
  let lastY = 0;
  let lastHeight = 0;
  const dataLength = data.length;
  const columnsTable = [
    {
      header: "No.",
      dataKey: "no"
    },
    {
      header: "No. Bag",
      dataKey: "bagNumber"
    },
    {
      header: "No. STT",
      dataKey: "sttNumber"
    },
    {
      header: "No. Referensi",
      dataKey: "referenceNumber"
    },
    {
      header: "Jenis Pengiriman",
      dataKey: "productType"
    },
    {
      header: "Total Koli",
      dataKey: "totalPieces"
    },
    {
      header: "Kota Asal",
      dataKey: "origin"
    },
    {
      header: "Penanda Paket",
      dataKey: "notes"
    }
  ];

  const columnStyles = {
    0: { cellWidth: 8 },
    1: { cellWidth: 25 },
    2: { cellWidth: 32 },
    3: { cellWidth: 25 },
    4: { cellWidth: 25 },
    5: { cellWidth: 25 },
    6: { cellWidth: 25 },
    7: { cellWidth: 25 },
  };

  doc.autoTable({
    body: data,
    columns: columnsTable,
    theme: "plain",
    startY: doc.internal.getCurrentPageInfo().pageNumber === 1 ? 43 : 0,
    margin: { top: 7, left: 10, right: 10, bottom: 7 },
    headStyles: {
      font: "helvetica",
      fontStyle: "bold",
      fillColor: "#f5f6f7",
      textColor: "#1a1421",
      fontSize: 6,
      halign: "left",
      valign: "top",
      cellPadding: { top: 4, right: 2, bottom: 4, left: 2 }
    },
    bodyStyles: {
      font: "helvetica",
      fontStyle: "normal",
      fillColor: "#fff",
      textColor: "#1a1421",
      fontSize: 6,
      halign: "left",
      valign: "top",
      lineColor: "#e0e0e0",
      lineWidth: { top: 0.1, right: 0, bottom: 0.1, left: 0 },
      cellPadding: { top: 3, right: 2, bottom: 3, left: 2 },
      cellWidth: "auto"
    },
    columnStyles: columnStyles,
    willDrawCell: (cell: any) => {
      drawCellData(cell);
      lastPage = cell.pageNumber;
      lastY = cell.cursor.y;
      lastHeight = cell.row.height;
      if (cell.row.index === dataLength - 2 && lastY + lastHeight + 20 > 270) {
        cell.cell.contentHeight = 100;
        cell.row.height = 100;
      }
    }
  });
  lastY = lastY + lastHeight + 20;
  lastY = lastY > 270 ? 270 : lastY;
  doc.setPage(lastPage);
  doc.setFontSize(8);
  doc.text("Staff Admin", 9 + 90, lastY);
  doc.text("(.................................)", 5 + 90, lastY + 20);
  doc.text("Kurir", 50 + 90, lastY);
  doc.text("(.................................)", 40 + 90, lastY + 20);
  doc.text("Operations Supervisor", 80 + 90, lastY);
  doc.text("(.................................)", 80 + 90, lastY + 20);

  doc.setProperties({
    title: `STI-DEST Manifest - ${head.stiDestId}`
  });
  doc.autoPrint();
  window.open(doc.output("bloburl"), "_blank");
};

const manifestPdf = (printData: StiDestDetail, logo: any) => {
  const data: Array<any> = [];
  let no = 1;
  for (const e of printData.stt) {
    data.push({
      no: no,
      bagNumber: e.sttBagVendorNo || e.sttBagNo,
      sttNumber: e.sttNo,
      referenceNumber: e.sttNoRefExternal || "-",
      productType: e.sttProductType,
      totalPieces: e.sttTotalPiece,
      origin: e.origin,
      notes: e.statusReturn || "-"
    });
    no++;
  }
  generateManifest(printData, data, logo);
};

export default manifestPdf;
